'use client';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Unstable_Grid2';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Stack } from '@mui/system';
import { ReadonlyURLSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import { useCallback } from 'react';
import Iconify from 'src/components/iconify';
import { useTranslate } from 'src/locales';
import { useSearchParams, usePathname, useRouter } from 'src/routes/hooks';
import { IEvent } from 'src/types/events';

import MapView from '../map-view/map-view';
import EventList from './event-list';
import LiveEvents from './live-events';

interface ScrollTopButton {
  window?: () => Window;
  children: React.ReactElement;
}
interface IEventsViewProps {
  events: IEvent[];
  liveEvents: IEvent[];
  eventsLoading: boolean;
  liveEventsLoading: boolean;
}
interface QueryObject {
  view: string;
  active: string;
}
function ScrollTop(props: ScrollTopButton) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function EventsView({
  events,
  liveEvents,
  eventsLoading,
  liveEventsLoading,
}: IEventsViewProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams()! as ReadonlyURLSearchParams & {
    size: number;
  };

  const isMap = searchParams.get('map') === 'true';
  const { t } = useTranslate('home');

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const handleQuery = (query: QueryObject) => {
    router.push(`${pathname}?${createQueryString(query.view, query.active)}`);
  };

  const onOpenMap = () => {
    router.push(`/?${createQueryString('map', 'true')}`);
    posthog.capture('show_map_clicked');
  };

  return (
    <>
      {isMap ? (
        <Stack flexDirection="column" flex={1}>
          <MapView settingQuery={handleQuery} />
        </Stack>
      ) : (
        <Grid container disableEqualOverflow sx={{ p: 2 }} spacing={2}>
          <Grid xs={12} md={12}>
            <LiveEvents list={liveEvents} isLoading={liveEventsLoading} />
          </Grid>
          <Grid xs={12} md={12}>
            <Stack direction="row" gap={1} alignItems="center" sx={{ mb: 1 }}>
              <Typography variant="h6" sx={{ color: (theme) => theme.palette.grey[50] }}>
                {t('sections.event-in-city')}
              </Typography>
              <Typography variant="h6">{searchParams.get('place') ?? t('near-you')}</Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={12}>
            <EventList events={events} isLoading={eventsLoading} />
          </Grid>
        </Grid>
      )}
      <ScrollTop>
        <Fab
          aria-label="scroll back to top"
          sx={{
            position: 'fixed',
            bottom: '10%',
            left: '50%',
            transform: 'translate(-50%, 5%)',
            borderRadius: 1,
            height: 2,
            width: 'fit-content',
            px: 1,
            py: 0,
          }}
          onClick={onOpenMap}
        >
          <Iconify icon="uil:map-pin" width={20} height={20} /> {t('map')}
        </Fab>
      </ScrollTop>
    </>
  );
}
