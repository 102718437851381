import { Skeleton, Typography } from '@mui/material';
// @mui
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import { IEvent } from 'src/types/events';

import EventItem from './event-item';

type Props = {
  events: IEvent[];
  isLoading: boolean;
};

export default function EventList({ events, isLoading }: Props) {
  const theme = useTheme();

  return (
    <>
      {isLoading && <Skeleton sx={{ borderRadius: 1.5, width: '8rem', height: '8rem' }} />}

      {!isLoading && !events.length && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          width={1}
          sx={{ mt: '4rem' }}
        >
          <Typography variant="caption" sx={{ color: theme.palette.grey[50] }}>
            No new events
          </Typography>
        </Stack>
      )}
      {!isLoading && events.length > 0 && (
        <Box
          gap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(5, 1fr)',
          }}
          sx={{ pb: 10 }}
        >
          {events.map((event) => (
            <EventItem key={event.id} event={event} />
          ))}
        </Box>
      )}
    </>
  );
}
