import { Skeleton, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
// @mui
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { IEvent } from 'src/types/events';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import EventCard from './event-card';

interface Props extends BoxProps {
  title?: string;
  subheader?: string;
  list: IEvent[];
  isLoading: boolean;
}

export default function LiveEventsDesktop({
  title,
  subheader,
  list,
  isLoading,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const swiperRef = useRef<any>(null);
  const mdDown = useResponsive('down', 'lg');
  const { t: tHome } = useTranslate('home');

  return (
    <Box sx={{ mb: 1, ...sx }} {...other}>
      <CardHeader
        title={tHome('sections.trending')}
        sx={{
          p: 0,
          mb: 3,
        }}
      />
      {isLoading && <Skeleton sx={{ borderRadius: 1.5, height: '20rem', flexShrink: 0 }} />}
      {!isLoading && !list.length && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          width={1}
          height="15rem"
        >
          <Typography variant="caption" sx={{ color: theme.palette.grey[50] }}>
            {tHome('empty-events')}
          </Typography>
        </Stack>
      )}
      {!isLoading && list.length > 0 && (
        <Swiper
          slidesPerView={mdDown ? 2 : 4}
          spaceBetween={5}
          pagination={{
            clickable: true,
          }}
          ref={swiperRef}
          modules={[Pagination]}
          className="mySwiper"
        >
          {list.map((item) => (
            <SwiperSlide key={item.id}>
              <EventCard key={item.id} event={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
}
